<template>
  <div style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; color: #9c4098; font-size: 22px; margin-bottom: 0">
              Daftar Kunjungan Pasien
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>

      <b-row class="pb-4">
        <b-col cols="12">
          <b-row style="margin: 0px -3px;">
            <b-col cols="4" class="">
              <label for="nama_lengkap">Nama Lengkap</label>
              <h6 class="ml-1"><strong>{{dataRm.nama_lengkap || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="nik">NIK</label>
              <h6 class="ml-1"><strong>{{dataRm.nik || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="no_rm">No. RM</label>
              <h6 class="ml-1" style="color: #9c4098;"><strong>{{dataRm.no_rm || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="tanggal_lahir">Tanggal Lahir</label>
              <h6 class="ml-1"><strong>{{$moment(dataRm.tanggal_lahir).format('LL') || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="jenis_kelamin">Jenis Kelamin</label>
              <h6 class="ml-1"><strong>{{dataRm.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan' || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="agama">Agama</label>
              <h6 class="ml-1"><strong>{{dataRm.agama || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="no_telp">No. Telepon</label>
              <h6 class="ml-1"><strong>{{dataRm.no_telp || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="no_bpjs">No. BPJS</label>
              <h6 class="ml-1"><strong>{{dataRm.no_bpjs || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="no_asuransi_lain">No. Asuransi Lain</label>
              <h6 class="ml-1"><strong>{{dataRm.no_asuransi_lain || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="status_keluarga">Status</label>
              <h6 class="ml-1"><strong>{{dataRm.status_keluarga || '-'}} / {{dataRm.status_perkawinan || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="asal_negara">Asal Negara</label>
              <h6 class="ml-1"><strong>{{dataRm.asal_negara || '-'}} / {{dataRm.nama_etnis || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="no_paspor">No. Pasport</label>
              <h6 class="ml-1"><strong>{{dataRm.no_paspor || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="nama_golongan_darah">Golongan Darah</label>
              <h6 class="ml-1"><strong>{{dataRm.nama_golongan_darah || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="pekerjaan">Pekerjaan</label>
              <h6 class="ml-1"><strong>{{dataRm.pekerjaan || '-'}}</strong></h6>
            </b-col>
            <b-col cols="4" class="">
              <label for="npwp">NPWP</label>
              <h6 class="ml-1"><strong>{{dataRm.npwp || '-'}}</strong></h6>
            </b-col>
          </b-row>
          <!-- <pre>{{rm}}</pre> -->
        </b-col>
        <b-col cols="12" md="12" lg="12">
          <div class="d-flex justify-content-between mb-2">
            <h6
              class="mt-3"
              style="font-weight: 700; color: #9c4098; font-size: 18px; margin-bottom: 0"
            >
              List Kunjungan
            </h6>
          </div>
          <b-table
            :items="listKunjungan"
            :fields="fieldsKunjungan"
            class="align-top"
            responsive
            show-empty
            small
            bordered
            striped
            hover
            :busy="busy"
          >
            <template #cell(no)="item">
              {{item.index + 1}}
            </template>
            <template #cell(diagnosa)="item">
              <div class="align-top">
                <ul v-if="item.item.diagnosa.length > 0">
                  <li v-for="(value, key) of item.item.diagnosa" :key="key">{{value}}</li>
                </ul>
                <div v-else class="text-center">-</div>
              </div>
            </template>
            <template #cell(tindakan)="item">
              <div class="align-top">
                <ul v-if="item.item.tindakan.length > 0">
                  <li v-for="(value, key) of item.item.tindakan" :key="key">{{value}}</li>
                </ul>
                <div v-else class="text-center">-</div>
              </div>
            </template>
            <template #cell(resep_obat)="item">
              <div class="align-top">
                <ul v-if="item.item.resep_obat.length > 0">
                  <li v-for="(value, key) of item.item.resep_obat" :key="key">{{value}}</li>
                </ul>
                <div v-else class="text-center">-</div>
              </div>
            </template>
            <template #cell(actions)="item">
              <!-- {{item}} -->
              <div class="d-flex justify-content-center mb-2">
                <b-button
                  variant="info"
                  size="sm"
                  class="ml-1"
                  @click="item.toggleDetails($event), (getDetailKunjungan(item.index))"
                  v-c-tooltip.hover.click="'Data lainya'"
                >
                  <b-icon icon="arrow-down"></b-icon>
                </b-button>
                <b-button
                  variant="info"
                  size="sm"
                  class="ml-1"
                  @click="is_data = item.item, $bvModal.show('modal-detail-kunjungan-rekam-medis')"
                  v-c-tooltip.hover.click="'Detail Kunjungan'"
                >
                  <CIcon name="cil-info" />
                </b-button>
              </div>
            </template>
            <template #row-details="row">
              <b-table-simple hover small responsive bordered>
                <b-thead class="table-color">
                  <b-tr>
                    <b-th class="text-center">Anamnesa</b-th>
                    <b-th class="text-center">Pemeriksaan Fisik</b-th>
                    <b-th class="text-center">Pemeriksaan Labolatorium</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <tr>
                    <td>
                      <ul v-if="row.item.detail && row.item.detail.anamnesa && row.item.detail.anamnesa.length > 0">
                        <li>Keluhan Utama: {{row.item.detail.anamnesa[0].keluhan_utama}}</li>
                        <li>Keluhan Tambahan: {{row.item.detail.anamnesa[0].keluhan_tambahan}}</li>
                        <li>Lama Sakit: {{row.item.detail.anamnesa[0].lama_sakit_hari || '0'}} hari {{row.item.detail.anamnesa[0].lama_sakit_bulan || '0'}} bulan {{row.item.detail.anamnesa[0].lama_sakit_tahun || '0'}} tahun</li>
                        <li>RPS: {{row.item.detail.anamnesa[0].rps}}</li>
                        <li>RPD: {{row.item.detail.anamnesa[0].rpd}}</li>
                        <li>RPK: {{row.item.detail.anamnesa[0].rpk}}</li>
                        <li>Sistole: {{row.item.detail.anamnesa[0].sistole}}</li>
                        <li>Diastole: {{row.item.detail.anamnesa[0].diastole}}</li>
                        <li>Berat Badan: {{row.item.detail.anamnesa[0].berat_badan}}</li>
                        <li>Tinggi Badan: {{row.item.detail.anamnesa[0].tinggi_badan}}</li>
                        <li>Resp Rate: {{row.item.detail.anamnesa[0].resp_rate}}</li>
                        <li>Heart Rate: {{row.item.detail.anamnesa[0].heart_rate}}</li>
                        <li>Lingkar Perut: {{row.item.detail.anamnesa[0].lingkar_perut}}</li>
                        <li>IMT : {{row.item.detail.anamnesa[0].imt}}</li>
                        <li>Hasil IMT: {{row.item.detail.anamnesa[0].hasil_imt}}</li>
                        <!-- <li>detak_nadi: {{row.item.detail.anamnesa[0].detak_nadi}}</li> -->
                        <!-- <li>nafas: {{row.item.detail.anamnesa[0].nafas}}</li> -->
                        <li>Saturasi (Sp02): {{row.item.detail.anamnesa[0].saturasi}}</li>
                        <li>Suhu: {{row.item.detail.anamnesa[0].suhu}}</li>
                        <li>Aktifitas fisik: {{row.item.detail.anamnesa[0].aktifitas_fisik}}</li>
                        <li>Detak Jantung: {{row.item.detail.anamnesa[0].detak_jantung}}</li>
                        <li>Triage: {{row.item.detail.anamnesa[0].triage}}</li>
                        <li>Skala nyeri: {{row.item.detail.anamnesa[0].skala_nyeri_angka}} - {{row.item.detail.anamnesa[0].skala_nyeri}}</li>
                        <li>Terapi: {{row.item.detail.anamnesa[0].terapi}}</li>
                        <li>Rencana tindakan: {{row.item.detail.anamnesa[0].rencana_tindakan}}</li>
                        <li>Tipe Askep: {{row.item.detail.anamnesa[0].tipe_askep}}</li>
                        <li>Deskripsi Askep: {{row.item.detail.anamnesa[0].deskripsi_askep}}</li>
                        <li>Edukasi: {{row.item.detail.anamnesa[0].edukasi}}</li>
                        <li>Observasi: {{row.item.detail.anamnesa[0].observasi}}</li>
                        <li>Keterangan Anamnesa: {{row.item.detail.anamnesa[0].keterangan_pool_anamnesa}}</li>
                        <li>Biopsikososial: {{row.item.detail.anamnesa[0].biopsikososial}}</li>
                        <li>Tindakan perawatan: {{row.item.detail.anamnesa[0].tindakan_keperawatan}}</li>
                        <li>Merokok: {{row.item.detail.anamnesa[0].merokok ? 'Ya' : 'Tidak'}}</li>
                        <li>Konsumsi alkohol: {{row.item.detail.anamnesa[0].konsumsi_alkohol ? 'Ya' : 'Tidak'}}</li>
                        <li>Kurang sayur buah: {{row.item.detail.anamnesa[0].kurang_sayur_buah ? 'Ya' : 'Tidak'}}</li>
                      </ul>
                    </td>
                    <td></td>
                    <td>
                      <div v-if="row.item.detail && row.item.detail.pemeriksaan_lab && row.item.detail.pemeriksaan_lab.length > 0">
                        <ul v-for="(x, key) of row.item.detail.pemeriksaan_lab" :key="key">
                          <li>Tindakan : {{x.nama_sub_tindakan_lab || '-'}}</li>
                          <div>Nilai : {{x.nilai_pemeriksaan || '-'}}</div>
                          <div>Hasil : {{x.hasil_pemeriksaan || '-'}}</div>
                          <!-- <li><pre>{{x}}</pre></li> -->
                        </ul>
                      </div>
                    </td>
                  </tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </b-table>
          <!-- <pre>{{listKunjungan}}</pre> -->
        </b-col>
      </b-row>
    </b-container>
    <ModalDetailKunjungan :id="'rekam-medis'" :dataKunjungan="is_data" />
  </div>
</template>
<script>
import ModalDetailKunjungan from './modal_detail_kunjungan/modal_detail_kunjungan.vue'
export default {
  components: {
    ModalDetailKunjungan,
  },
  data() {
    return {
      busy: false,
      is_data: {},
      dataRm: {},
      listKunjungan: [],
      fieldsKunjungan: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: false,
          class: "table-number text-center",
        },
        {
          key: "nama_tanggal",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "diagnosa",
          label: "Diagnosa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left align-top",
        },
        {
          key: "tindakan",
          label: "Tindakan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "resep_obat",
          label: "Resep Obat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_poli",
          label: "Poli / Ruangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_kamar",
          label: "Kamar",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "rujuk_internal",
          label: "Rujuk Internal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_puskesmas",
          label: "Puskesmas",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          sortDirection: "desc",
          class: "text-center",
        },
      ],
    }
  },
  activated() {
    const vm = this
    vm.getData()
  },
  methods: {
    async getData(){
      const vm = this
      vm.busy = true
      try {
        // console.log('id', vm.$route.params.id)
        let dataRm = await vm.$axios.post('/rm/list', {rm_id: vm.$route.params.id})
        console.log('dataRm', dataRm)
        if(dataRm.data.status == 200 && dataRm.data.data.length > 0){
          vm.dataRm = dataRm.data.data[0]
          // let listKunjungan = await vm.$axios.post('/kunjungan/list', {no_rm: vm.dataRm.no_rm})
          // let listKunjungan = await vm.$axios.post('/kunjungan/list_kunjungan', {rm_id: vm.dataRm.rm_id})
          let listKunjungan = await vm.$axios.post('/kunjungan/list_kunjungan', {
            no_rm: vm.dataRm.no_rm,
          })
          console.log('listKunjungan semua kunjungan', listKunjungan)
          if(listKunjungan.data.status == 200){
            vm.listKunjungan = listKunjungan.data.data.map(x => {
              let expired = x.tanggal_lahir ? vm.$moment(x.tanggal_lahir) : vm.$moment();
              let now = vm.$moment();
              let hasil = vm.$moment.duration(now.diff(expired));
              x.umur = `${hasil.years()} tahun ${hasil.months()} bulan ${hasil.days()} hari`;
              return {
                ...x,
                nama_tanggal: vm.$moment(x.tanggal_daftar).format('LLLL'),
              }
            })
          }
        }else{
          // vm.triggerAlert({ variant: "warning", msg: 'Rekam Medis Tidak Ditemukan', showing: true })
        }
      } catch (error) {
        console.log(error)
        vm.triggerAlert({ variant: "warning", msg: 'Terjadi kesalahan sistem', showing: true });
      } finally {
        vm.busy = false
      }
    },
    async getDetailKunjungan(idx){
      const vm = this
      vm.busy = true
      try {
        console.log(idx)
        if(idx !== undefined){
          // e.toggleDetails
          let listKunjungan = await vm.$axios.get('/kunjungan/detail_kunjungan_by_id/' + vm.listKunjungan[idx].kunjungan_id)
          console.log('listKunjungan', listKunjungan)
          if(listKunjungan.data.status == 200 && listKunjungan.data.data.length > 0){
            const x = JSON.parse(JSON.stringify(vm.listKunjungan))
            x[idx].detail = listKunjungan.data.data[0]
            vm.listKunjungan = x
          }else{
            // vm.triggerAlert({ variant: "warning", msg: 'Kunjungan Tidak Ditemukan', showing: true })
          }
        }
      } catch (error) {
        console.log(error)
        vm.triggerAlert({ variant: "warning", msg: 'Terjadi kesalahan sistem', showing: true });
      } finally {
        vm.busy = false
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
  #page-laboratorium{
    font-family: 'Inter', sans-serif !important;
  }
  td, th {
    vertical-align: top !important;
  }
  th{
    background-color: #D52F65;
    color:#fff;
    font-weight: 600;
  }
  .abang {
    color:#D52F65;
  }
  th,td{
    border:1px solid #F1F1F1 !important
  }

  .title-16{
    font-weight: 700;
    color:#D52F65;
    font-size: 16px;
  }

  .btn-primary{
    background-color: #9C4098;
  }

  .btn-outline-dark{
    /* color: #7C7C7C; */
    border-color: #7C7C7C;
    font-weight: 600;
  }

  .card-columns .card-body{
    padding: 10px;
  }

  .title-checkbox{
    font-weight: 600;
    color: #202020;
  }

  .font-checbox{
    font-weight: 400;
  }

  #collapse-1 .card{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .custom-font-label{
    font-size:12px;
    font-weight:600;
    color:#7C7C7C
  }

  .custom-font-value{
    font-size:14px;
    font-weight:400;
    color:#202020
  }
</style>